import moment from 'moment';
import { getItemByValue, isNotEmpty } from './utils';
import { USER_ROLES } from '../constants';
import { formatCurrencyValueLabel } from './helpers';
import { defaultSortCombination, getSortCombination, SortOrder } from './filters';

const getFinancialData = (financialObject, data) => {
  // TODO check optional chaining (?.) for financialObject, project, projectObject
  const {
    projectTypes, contentType, financialTypesKeys, role = USER_ROLES.ADMIN,
  } = data;
  const isFreelancer = [USER_ROLES.WRITER, USER_ROLES.DESIGNER].includes(role);
  const isAdmin = [USER_ROLES.ADMIN, USER_ROLES.SUPER].includes(role);
  const isClient = USER_ROLES.CLIENT === role;
  const { project } = financialObject;
  const isCorrection = financialObject.financial_type === financialTypesKeys.CORRECTION_PROJECT;
  const projectObject = isCorrection ? financialObject.correction_project : financialObject.project;

  const projectId = project?.id ?? '';
  const client = projectObject?.client ?? '';
  const projectCode = projectObject?.code ?? '';
  const projectInnerQuantity = project?.quantity ?? 0;
  const projectFinancialQuantity = financialObject?.quantity ?? 0;
  const applyMinimumFeeClient = !!financialObject.minimum_fee_client;
  const applyMinimumFeeFreelancer = !!financialObject.minimum_fee_freelancer;
  const applyDiscountsClient = financialObject?.apply_discounts_client ?? 0;
  const applyDiscountsFreelancer = financialObject?.apply_discounts_freelancer ?? 0;
  const applyPriorityClient = !!(financialObject?.apply_priority_client ?? 0);
  const applyPriorityFreelancer = !!(financialObject?.apply_priority_freelancer ?? 0);
  const priceWithDiscount = financialObject?.price_with_discount ?? 0;
  const costWithDiscount = financialObject?.cost_with_discount ?? 0;
  const projectTypeId = project?.service_id ?? null;
  const contentTypeId = project?.content_type_id ?? null;
  const projectTypeObj = projectTypeId
    ? getItemByValue({
      array: projectTypes,
      keyToCheck: 'id',
      findValue: projectTypeId,
    })
    : null;
  const projectType = projectTypeObj?.name ?? '';

  const projectContentTypeObj = contentTypeId
    ? getItemByValue({
      array: contentType,
      keyToCheck: 'id',
      findValue: contentTypeId,
    })
    : null;
  const projectContentType = projectContentTypeObj?.name ?? '';

  // TODO check logic please
  const deadlineAt = financialObject?.deadline_at ?? '';
  const isMisedDeadline = moment(financialObject.final_complete_date).isAfter(deadlineAt);
  // TODO should use constant or new field from backend?
  const changeType = [7, 8].includes(projectTypeId) ? 'one-off-project' : 'project';
  const createdAt = financialObject?.created_at ?? '';

  const isMinimumFee = [
    (isAdmin || isClient) && applyMinimumFeeClient,
    (isAdmin || isFreelancer) && applyMinimumFeeFreelancer,
  ].some(Boolean);

  const poNumber = (isAdmin || isClient) ? project?.po_number ?? null : null;

  const isValidDiscountEffectingNumber = (priceWithDiscount > 0 || costWithDiscount > 0);
  const wasDiscountsApplied = [
    (isAdmin || isClient) && applyDiscountsClient,
    (isAdmin || isFreelancer) && applyDiscountsFreelancer,
  ].some(Boolean);
  const isDiscountEffectingNumber = wasDiscountsApplied && isValidDiscountEffectingNumber;

  const isPriorityFee = [
    (isAdmin || isClient) && applyPriorityClient,
    (isAdmin || isFreelancer) && applyPriorityFreelancer,
  ].some(Boolean);

  const quantityDanger = financialObject.quantity_danger ?? 0;
  const isQuantityInvalid = (projectFinancialQuantity === 0 && projectInnerQuantity === 0)
    && (financialObject.final_state !== 'Completed');

  // project.project may be null, as this component will render financial of deleted projects too
  const departmentName = projectObject?.project_department?.department_name;

  const projectTitle = projectObject?.name ?? '';

  return {
    financialObject,
    isCorrection,
    projectObject,
    projectId,
    client,
    projectCode,
    applyDiscountsClient,
    applyDiscountsFreelancer,
    applyPriorityClient,
    applyPriorityFreelancer,
    priceWithDiscount,
    costWithDiscount,
    projectType,
    projectContentType,
    isMisedDeadline,
    changeType,
    createdAt,
    isMinimumFee,
    isDiscountEffectingNumber,
    isPriorityFee,
    quantityDanger,
    isQuantityInvalid,
    departmentName,
    projectTitle,
    poNumber,
  };
};

export {
  getFinancialData,
};

export const getPerWordPriceByService = (price) => (
  isNotEmpty(price)
    ? `€ ${formatCurrencyValueLabel(price)} /word`
    : '-'
);

export const getPerHourPrice = (price) => (
  isNotEmpty(price)
    ? `€ ${formatCurrencyValueLabel(price)} /hour`
    : '-'
);

const getDiscountPercentage = (discount) => (
  isNotEmpty(discount)
    ? `${discount}%`
    : '-'
);

export const getItemPrices = (pricePlan) => {
  const writingPrice = getPerWordPriceByService(pricePlan.writing_price);
  const editingPrice = getPerWordPriceByService(pricePlan.editing_price);
  const reWritingPrice = getPerWordPriceByService(pricePlan.rewriting_price);
  const localisationPrice = getPerWordPriceByService(pricePlan.localisation_price);
  // TODO check this price
  const translationPrice = getPerWordPriceByService(pricePlan.localisation_price);
  const translationDiscount = getDiscountPercentage(pricePlan.translation_percentage);

  const creativePrice = getPerHourPrice(pricePlan.creative_price);

  const consultancyPrice = getPerHourPrice(pricePlan.professional_service?.senior_price);

  return [
    { id: 'writing', price: writingPrice },
    { id: 'editing', price: editingPrice },
    { id: 'reWriting', price: reWritingPrice },
    { id: 'localisation', price: localisationPrice },
    { id: 'translation', price: translationPrice, discount: translationDiscount },
    { id: 'creative', price: creativePrice },
    { id: 'consultancy', price: consultancyPrice },
  ];
};

export const getSortColumns = (t) => [
  { id: 'writing_price', title: t('writing') },
  { id: 'editing_price', title: t('editing') },
  { id: 'rewriting_price', title: t('re_writing') },
  { id: 'localisation_price', title: t('localisation') },
  { id: 'translation_percentage', title: t('translation') },
  { id: 'creative_price', title: t('creative') },
];

export const sortByColumn = ({ sortKey, filters, applyFilter }) => {
  const { orderBy: prevSortKey, sort: prevSortOrder } = filters;
  const sortCombination = getSortCombination({ prevSortKey, prevSortOrder, sortKey });
  // reset sorting to default if order is empty
  const newSortCombination = sortCombination.sortOrder === SortOrder.EMPTY
    ? defaultSortCombination
    : sortCombination;

  const {
    sortKey: newOrderBy,
    sortOrder: newSort,
  } = newSortCombination;
  applyFilter({ orderBy: newOrderBy, sort: newSort });
};
