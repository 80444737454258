import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';

import App from './components/App';
import { unregister } from './registerServiceWorker';

// styles
import './assets/styles/index.scss';
// reactstrap use this styles
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useThirdPartyCookiesChecker } from './hooks';

(process.env.NODE_ENV !== 'production') && console.info(process.env.NODE_ENV, 'environment');

if (process.env.NODE_ENV === 'production') {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
  SENTRY_DSN && Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

const Root = () => {
  const state = useThirdPartyCookiesChecker();
  const {
    isCookiesChecking,
    hasCookiesChecked,
    isCookiesEnabled,
    isFailed,
  } = state;

  !hasCookiesChecked && !isCookiesChecking
    ? console.info('👀 Let check cookies')
    : isCookiesChecking
      ? console.info('🔁 Cookies checking...')
      : isFailed
        ? console.info('💥 Cookies checking failed. Try again')
        : isCookiesEnabled
          ? console.info('🔓 Cookies is enabled!')
          : console.info('🔒 Cookies is disabled!');

  return <App />;
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(<Root />);
// if (module.hot) {
//     module.hot.accept('./components/App', () => {
//         ReactDOM.render(<App />, rootElement)
//     })
// }
unregister();
